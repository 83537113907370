import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { usePageContext } from '../../providers/PageProvider';
import { MainContent } from './styles/MainContent';
import { CheckBox } from '@components/form-elements/CheckBox';
import { CustomTable } from '@components/Table';

export const Content = () => {
  const { isLoading, settings, updateSettings } = usePageContext();

  const toggleSettings = (name: string) => {
    updateSettings({ ...settings, [name]: !settings[name] });
  };

  if (!settings) {
    return <LinearProgressLoader active={isLoading} />;
  }

  return (
    <>
      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <CustomTable
          className="table"
          data={[
            { type: 'jobAssigned', name: 'Job Assigned' },
            { type: 'repositoryAssigned', name: 'Repository Assigned' },
            { type: 'transcriptionInProgress', name: 'Transcription In Progress' },
            { type: 'transcriptionIsReady', name: 'Transcription In Ready' },
          ]}
          head={[
            {
              headComponent: () => <td>TYPE</td>,
              render: (data: any) => <p key={data.type}>{data.name}</p>,
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Email</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.emailNotifications === undefined ? false : settings.emailNotifications}
                      onChange={() => toggleSettings('emailNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.emailNotifications === undefined ? false : !settings.emailNotifications}
                    checked={settings[`${data.type}Email`] === undefined ? false : settings[`${data.type}Email`]}
                    onChange={() => toggleSettings(`${data.type}Email`)}
                  />
                </div>
              ),
            },
            {
              headComponent: () => (
                <td>
                  <div className="items-center flex flex-col justify-center">
                    <div className="label">Push</div>
                    <CheckBox
                      className="checkbox"
                      checked={settings.pushNotifications === undefined ? false : settings.pushNotifications}
                      onChange={() => toggleSettings('pushNotifications')}
                    />
                  </div>
                </td>
              ),
              render: (data: any) => (
                <div className="flex items-center justify-center" key={data.type}>
                  <CheckBox
                    className="switch"
                    disabled={settings.pushNotifications === undefined ? false : !settings.pushNotifications}
                    checked={settings[`${data.type}Push`] === undefined ? false : settings[`${data.type}Push`]}
                    onChange={() => toggleSettings(`${data.type}Push`)}
                  />
                </div>
              ),
            },
          ]}
        />
      </MainContent>
    </>
  );
};
