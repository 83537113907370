import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { NotificationsService, UpdateNotificationSettingsDto } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  settings: any;
  updateSettings: (v: any) => void;
  isLoading: boolean;
};

const PageContext = createContext<TState>({
  settings: {},
  updateSettings: () => {},
  isLoading: false,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<any>({});

  const fetch = async () => {
    setIsLoading(true);
    const data = await call(NotificationsService.getNotificationSettings());
    setSettings(data);
    setIsLoading(false);
  };

  const updateSettings = async (settings: UpdateNotificationSettingsDto) => {
    setIsLoading(true);
    const data = await call(NotificationsService.updateNotificationSettings({ requestBody: settings }));
    setSettings(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageContext.Provider
      value={{
        settings,
        updateSettings,
        isLoading,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
